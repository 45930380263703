'use client';
import { useState, useRef } from 'react';
import { useRouter } from 'next/navigation';
import Downshift from 'downshift';
import Image from 'next/image';
import { Product, PaginatedResponse } from '../types/types';

interface SearchBarProps {
  inputClassName?: string;
}

export default function SearchBar({ inputClassName = '' }: SearchBarProps) {
  const router = useRouter();
  const [items, setItems] = useState<Product[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const debounceRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * Enterキー押下で /search?query=... へ遷移
   */
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchValue.trim() !== '') {
      router.push(`/search?query=${encodeURIComponent(searchValue)}`);
    }
  };

  /**
   * サーバーサイドに問い合わせる関数
   */
  async function fetchSearch(query: string) {
    if (!query) {
      setItems([]);
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const url = `/api/products/search/?query=${encodeURIComponent(query)}`;
      const res = await fetch(url);
      if (!res.ok) {
        throw new Error(`Fetch failed with status ${res.status}`);
      }

      // ページネーションレスポンスを想定
      const data: PaginatedResponse<Product> = await res.json();
      if (data.results && Array.isArray(data.results)) {
        setItems(data.results);
        console.log('Suggestions fetched:', data.results);
      } else {
        console.error('[SearchBar] Unexpected API response structure:', data);
        setError('検索結果が不正です。');
        setItems([]);
      }
    } catch (err) {
      console.error('[SearchBar] search error:', err);
      setError('検索に失敗しました。');
      setItems([]);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={`relative w-full max-w-md px-4 ${inputClassName}`}>
      <span className="absolute left-4 top-1/2 -translate-y-1/2 px-4 z-10">
        <Image src="/images/search.png" alt="Search Icon" width={16} height={16} />
      </span>

      <Downshift<Product>
        itemToString={(item) => (item ? item.name : '')}
        onChange={(selection) => {
          if (selection && selection.id) {
            router.push(`/products/${selection.id}`);
          }
        }}

        onInputValueChange={(val) => {
          const inputVal = val || '';
          setSearchValue(inputVal);

          if (debounceRef.current) clearTimeout(debounceRef.current);
          debounceRef.current = setTimeout(() => {
            fetchSearch(inputVal);
          }, 300);
        }}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          highlightedIndex,
          isOpen,
          inputValue: dsInputValue,
        }) => {

          const results = Array.isArray(items) ? items.slice(0, 5) : [];
          console.log('Type of suggestions:', Array.isArray(items)); // デバッグ用ログ

          return (
            <div className="relative w-full">
              <input
                {...getInputProps({
                  className:
                    'w-full p-2 rounded-full border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 text-black pl-12',
                  placeholder: '検索する',
                  onKeyPress: handleKeyPress,
                })}
                value={dsInputValue || ''}
              />

              {isOpen && results.length > 0 && (
                <ul
                  {...getMenuProps()}
                  className="absolute z-20 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto shadow-lg"
                >
                  {results.map((item, index) => {
                    const isHighlighted = highlightedIndex === index;
                    return (
                      <li
                        key={item.id}
                        {...getItemProps({ item, index })}
                        className={`cursor-pointer px-4 py-2 transition-colors ${
                          isHighlighted ? 'bg-blue-500 text-white' : 'bg-white text-black'
                        }`}
                      >
                        {item.brand ? `[${item.brand}] ` : ''}
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              )}

              {isOpen && results.length === 0 && !loading && !error && (
                <ul
                  {...getMenuProps()}
                  className="absolute z-20 w-full bg-white border border-gray-300 rounded-md mt-1 max-h-60 overflow-auto shadow-lg"
                >
                  <li className="p-2 text-center text-gray-500">一致する商品がありません。</li>
                </ul>
              )}

              {loading && (
                <div className="absolute left-0 top-full mt-1 bg-white text-gray-500 text-sm p-2 shadow">
                  検索中...
                </div>
              )}

              {error && (
                <div className="absolute left-0 top-full mt-1 bg-white text-red-500 text-sm p-2 shadow">
                  {error}
                </div>
              )}
            </div>
          );
        }}
      </Downshift>
    </div>
  );
}
